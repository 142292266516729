<template>
  <div
    class="contain-select-width"
  >
    <v-select
      :items="optionItems"
      :dense="dense"
      outlined
      hide-details
      :label="label"
      v-bind="$props"
      v-on="$listeners"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "WeightSelector",

  props: {
    label: {
      type: String,
      required: false,
      default: "Percentage"
    },

    value: {
      type: Number,
      required: false,
      default: 0.05
    },

    dense: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  computed: {
    optionItems() {
      const items = [
        {
          text: "> 1%",
          value: 0.01
        }
      ]

      for (let i = 1; i < 20; i++) {
        items.push({
          text: `> ${5 * i}%`,
          value: (5 * i) / 100
        })
      }

      return items
    }
  },

  mounted() {
    this.$emit('input', this.value)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  min-width 8em
  width 8em
</style>
